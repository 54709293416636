import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import ImageList, { imageListStyle } from '~components/ImageList';
import Text from '~components/Text';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../../enums';

const FuturePage: React.FC = () => (
  <Layout>
    <SEO title="Future Ready" desc="" pathname="/aspirations/future-ready" />
    <Hero title="Enable Future Ready education, research, programs, services and experiences – for students, employees and our community">
      <StaticImage src="../../images/student-adjusting-equipment.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container mb-24">
      <div tw="max-w-5xl">
        <Text>
          We are transforming the way we deliver the educational experience. We’ve redefined the
          meaning of campus to meet our students’ needs and to support the balance of school, work,
          and family commitments.
        </Text>
        <Text>
          We offer a continuum of diverse learning modalities for our students and flexible working
          options to our employees.
        </Text>
      </div>
    </div>
    <ImageList
      title="Strategic Direction"
      items={[
        'Enable the digital transformation of teaching and learning through the implementation of our Digital Learning Strategy.',
        'Develop a strategic program portfolio framework focusing on new programs and entry points, individualized pathways and laddering opportunities, from micro-credentials through to degrees.',
        'Develop a student services model that integrates flexible and personalized options to support an optimal student experience inside and outside of the classroom.',
        'Develop and start to implement a framework that will determine which operational functions can be delivered through flexible and hybrid work arrangements.',
        'Engage emerging researchers in our college community, to expand the impact of our research activities in an environment that fosters scholarly collaborations and creativity, and innovation, and that embraces inclusion and diversity.'
      ]}
    >
      <StaticImage
        src="../../images/students-learning.jpg"
        alt=""
        layout="fullWidth"
        style={imageListStyle}
      />
    </ImageList>
    <div tw="mb-24 bg-orange text-gray-800 py-12">
      <div tw="container">
        <div tw="justify-between space-x-6 items-center md:(flex)">
          <Check tw="h-32 w-32 flex-shrink-0 md:(order-2 h-48 w-48)" />
          <div tw="md:(order-1)">
            <Heading level="h2" tw="mt-0">
              Leadership Outcome
            </Heading>
            <Text tw="text-2xl font-bold">
              We will be the college of choice for a flexible, accessible and innovative learning
              and working environment.
            </Text>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="max-w-5xl mb-12">
        <Heading level="h2" tw="text-rose">
          Building on Momentum
        </Heading>
        <Text tw="text-xl font-bold">
          We work closely with regional and local employers to develop skilled and workplace-ready
          graduates through collaborative planning, co-op programs and work-integrated learning
          initiatives, with support for lifelong learning to keep grads’ skills relevant.
        </Text>
        {/* <Heading level="h3">1. Mohawk first</Heading>
        <Text>
          Mohawk has Canada’s first research centre dedicated to improving college student success.
          The College Student Success Innovation Centre focuses on interventions throughout the
          student lifecycle that are backed by research about what works to improve student
          retention and graduation.
        </Text>
        <Heading level="h3">2. Mohawk best</Heading>
        <Text>
          Mohawk’s Future Ready Partnerships with organizations and companies in the community
          provide experiential learning opportunities to connect students with real-world clients,
          for example, Broadcast Media students work directly with clients who come to the college
          through the McKeil School of Business, to produce promotional and social media videos.
        </Text>
        <Heading level="h3">3. Mohawk best</Heading>
        <Text>
          Many Mohawk faculty members are incorporating augmented or virtual reality (AVR) into
          their teaching to engage students in learning. Another important use of AVR is to prepare
          students for their placements, or to stand in for placements that aren’t available, from
          Support Service Workers to Construction Engineering Technician-Building Renovation to
          Electrical and Computer Engineering programs.
        </Text>
        <Heading level="h3">4. Mohawk only</Heading>
        <Text>
          Mohawk is the only college in Canada with sophisticated virtual maintenance trainers, the
          same used to train working mechanics for the Boeing 737. The 10 the virtual trainers are
          located at the college’s new aviation campus at Hamilton International Airport.
        </Text> */}
      </div>
      <StyledHeading>Aspirations</StyledHeading>
      <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/truth-reconciliation"
          Icon={<Directions tw="w-12" />}
        >
          Meaningfully advance Truth and Reconciliation
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/celebrate-every-person"
          Icon={<Access tw="w-12" />}
        >
          Be a place that honours, values and celebrates the whole of every person
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/education" Icon={<Pointer tw="w-12" />}>
          Ensure access to education and reduce barriers for all
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/workforce-development"
          Icon={<Bag tw="w-12" />}
        >
          Lead in workforce development
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/climate-change" Icon={<Globe tw="w-12" />}>
          Make a measurable impact on climate change
        </IconCard>
      </div>
    </div>
  </Layout>
);

export default FuturePage;
